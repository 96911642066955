import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import growa from "../../Assets/Projects/growa.png";
import swappy from "../../Assets/Projects/swappy.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={growa}
              isBlog={false}
              title="Growa.AI"
              description="Growa.ai Ltd epitomizes the fusion of advanced technology and agricultural expertise to redefine the landscape of Precision Agriculture."
              ghLink="https://support-138.gitbook.io/growa"
              demoLink="https://growa.ai"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={swappy}
              isBlog={false}
              title="Forint Finance - Swappy"
              description="Swappy is the Trading Platform developed by Forint Finance, an evolution of the Cross-chain swap and Dex concept, a 'hybrid exchange'."
              ghLink="https://forintfinance.com"
              demoLink="https://swappy.exchange/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
